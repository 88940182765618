exports.components = {
  "component---src-pages-dozivotne-byvanie-js": () => import("./../../../src/pages/dozivotne-byvanie.js" /* webpackChunkName: "component---src-pages-dozivotne-byvanie-js" */),
  "component---src-pages-garantovana-investovanie-js": () => import("./../../../src/pages/garantovana-investovanie.js" /* webpackChunkName: "component---src-pages-garantovana-investovanie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investovanie-do-nehnutelnosti-js": () => import("./../../../src/pages/investovanie-do-nehnutelnosti.js" /* webpackChunkName: "component---src-pages-investovanie-do-nehnutelnosti-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-nasesluzby-js": () => import("./../../../src/pages/nasesluzby.js" /* webpackChunkName: "component---src-pages-nasesluzby-js" */),
  "component---src-pages-nova-stranka-js": () => import("./../../../src/pages/nova-stranka.js" /* webpackChunkName: "component---src-pages-nova-stranka-js" */),
  "component---src-pages-ochrana-pred-drazbou-js": () => import("./../../../src/pages/ochrana-pred-drazbou.js" /* webpackChunkName: "component---src-pages-ochrana-pred-drazbou-js" */),
  "component---src-pages-peniaze-pred-predajom-js": () => import("./../../../src/pages/peniaze-pred-predajom.js" /* webpackChunkName: "component---src-pages-peniaze-pred-predajom-js" */),
  "component---src-pages-rychly-vykup-js": () => import("./../../../src/pages/rychly-vykup.js" /* webpackChunkName: "component---src-pages-rychly-vykup-js" */),
  "component---src-pages-spatny-leasing-js": () => import("./../../../src/pages/spatny-leasing.js" /* webpackChunkName: "component---src-pages-spatny-leasing-js" */),
  "component---src-pages-uver-bez-rucenia-js": () => import("./../../../src/pages/uver-bez-rucenia.js" /* webpackChunkName: "component---src-pages-uver-bez-rucenia-js" */),
  "component---src-pages-uver-pre-firmu-js": () => import("./../../../src/pages/uver-pre-firmu.js" /* webpackChunkName: "component---src-pages-uver-pre-firmu-js" */)
}

